<template>
  <div class="page">
    <Tables
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      @getList="getList"
      @clearSearch="clearSearch"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.title"
          size="small"
          clearable
          placeholder="按操作内容搜索"
        ></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.username"
          size="small"
          clearable
          placeholder="按用户名搜索"
        ></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-date-picker
          v-model="searchFormData.time"
          style="width: 380px"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :unlink-panels="true"
          size="small"
          clearable
        >
        </el-date-picker>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="title"
        title="操作内容"
        align="center"
      />
      <vxe-table-column
        slot="table-item"
        field="ip"
        title="ip"
        align="center"
      />
      <vxe-table-column
        slot="table-item"
        field="username"
        title="用户名"
        align="center"
      />
      <vxe-table-column
        slot="table-item"
        field="create_time"
        title="操作时间"
        align="center"
      />
      <vxe-table-column
        slot="table-item"
        title="操作"
        width="100"
        align="center"
        v-if="$hasAccess('system.log/view')"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            plain
            @click="showDetail(row)"
            icon="el-icon-tickets"
          >详情
          </el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <el-dialog
      title="日志详情"
      :visible.sync="dialogShow"
      :width="dialogWidth"
    >

      <div class="dialog">
        <div class="dialog-item">
          <div class="title">id</div>
          <div class="desc">{{dialogContent.log_id}}</div>
        </div>
        <div class="dialog-item">
          <div class="title">用户名</div>
          <div class="desc">{{dialogContent.realname}}</div>
        </div>
        <div class="dialog-item">
          <div class="title">标题</div>
          <div class="desc">{{dialogContent.title}}</div>
        </div>
        <div class="dialog-item">
          <div class="title">内容</div>
          <div class="desc">{{dialogContent.content}}</div>
        </div>

        <div class="dialog-item">
          <div class="title">IP</div>
          <div class="desc">{{dialogContent.ip}}</div>
        </div>
        <div class="dialog-item">
          <div class="title">useragent</div>
          <div class="desc">{{dialogContent.useragent}}</div>
        </div>
        <div class="dialog-item">
          <div class="title">操作时间</div>
          <div class="desc">{{dialogContent.create_time}}</div>
        </div>
      </div>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          @click="closeDialog"
        >确 定</el-button>
        <el-button
          size="small"
          plain
          @click="closeDialog"
        >取 消</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import Tables from '@/components/tables'
import { formatTime } from '@/utils/util'

export default {
  name: 'system-log-list',
  components: {
    Tables
  },
  computed: {
    hasAccess () {
      return function (val) {
        return this.$store.state.user.userInfo.route.includes(val)
      }
    }
  },
  data () {
    return {
      tableName: '系统日志',
      tableData: [],
      totalPage: 0,
      isLoading: false,
      searchFormData: {
        title: '',
        username: '',
        time: ''
      },
      dialogShow: false,
      dialogWidth: '70%',
      dialogContent: {}
    }
  },
  methods: {
    getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      let searchParams = {
        title: this.searchFormData.title,
        username: this.searchFormData.username
      }
      if (this.searchFormData.time instanceof Array) {
        searchParams.start_time = formatTime(this.searchFormData.time[0])
        searchParams.end_time = formatTime(this.searchFormData.time[1])
      }
      this.isLoading = true
      this.$api.System.GetLogList(params, searchParams).then(res => {
        this.totalPage = res.total
        this.tableData = res.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
      })
    },
    clearSearch () {
      this.searchFormData = {
        title: '',
        username: '',
        time: ''
      }
    },
    showDetail (row) {
      const params = {
        token: this.$store.state.user.token,
        log_id: row.log_id
      }
      this.$api.System.GetLogDetail(params).then(res => {
        this.dialogShow = true
        this.dialogContent = res
      })
    },
    closeDialog () {
      this.dialogShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-item {
  display: flex;
  align-items: flex-start;
  margin: 5px 0;

  .title {
    width: 80px;
    flex-shrink: 0;
  }
}
</style>
